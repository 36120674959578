import { GuestFields } from "../../store/slices/guestsSlice";

export const DEFAULT_GUEST_FIELDS: GuestFields = {
  address1: "",
  address2: "",
  city: "",
  country: "",
  dayOfBirth: "",
  monthOfBirth: "",
  yearOfBirth: "",
  email: "",
  given_name: "",
  last_name: "",
  gender: "",
  lead_passenger: false,
  nationality: "",
  phone: "",
  state: "",
  title: "",
  zip_code: "",
  passport_number: "",
  issue_date: "",
  expiry_date: "",
  place_of_birth: "",
  country_of_issue: "",
  request: "",
};
