import { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react"; // React hooks for managing component state and lifecycle
import { useNavigate, useParams, useSearchParams } from "react-router-dom"; // Importing hooks for navigation and URL parameters from React Router.
import { useTranslation } from "react-i18next"; // Translation hook for multilingual support

import { useLazyCreateReservationQuery } from "../../../../../store/services/CabinSelectService"; // Import hook for creating reservation query
import { useTypedDispatch, useTypedSelector } from "../../../../../store/store"; // Import typed dispatch and selector hooks from Redux store
import { GuestFields } from "../../../../../store/slices/guestsSlice"; // Import type for guest fields from Redux store
import { updateRooms } from "../../../../../store/slices/roomsSlice"; // Import action for updating rooms from Redux store
import { showToast } from "../../../../../store/slices/toastSlice"; // Import action for displaying toast messages from Redux store
import { prepareParams } from "../../../../../utils/helpers/rooms"; // Import function for preparing URL parameters
import TOAST from "../../../../../utils/constants/toast"; // Import constant for toast messages

import Button from "../../../../shared/Button"; // Import Button component

import styles from "./index.module.scss"; // Import styles specific to this component

// Define props interface for the SubmitButton component
interface ISubmitButtonProps {
  onClick: () => void; // Callback function for button click event
}

// type Documents = Array<{
//   type: string;
//   number: string;
//   issue_date: string;
//   expiry_date: string;
//   place_of_birth: string;
//   country_of_issue: string;
//   gender: string;
// }>;

// Define the type for the payload sent when submitting the form
type SubmitButtonPayload = Record<
  number,
  Record<
    number,
    {
      inputs: GuestFields;
    }
  >
>;

/**
 * SubmitButton component for handling form submission.
 * @param {Function} onClick - Function to execute on button click
 * @param {React.RefObject<{ submit: (guests: SubmitButtonPayload) => void }>} ref - Ref object for exposing submit function
 * @returns {JSX.Element} SubmitButton component
 */
const SubmitButton = forwardRef(function SubmitButton(
  { onClick }: ISubmitButtonProps,
  ref: ForwardedRef<{
    submit: (guests: SubmitButtonPayload) => void;
  }>,
) {
  // Hooks
  const { t } = useTranslation(); // Translation hook
  const navigate = useNavigate(); // Navigation hook
  const dispatch = useTypedDispatch(); // Typed dispatch hook
  const [reservation] = useLazyCreateReservationQuery(); // Lazy query hook for creating reservation

  const { cruiseId } = useParams(); // Get cruise ID from URL parameters
  const [searchParams] = useSearchParams(); // Get search parameters from URL
  const totalRooms = +(searchParams.get("rooms") ?? 0); // Total number of rooms
  const currentRoomNumber = +(searchParams.get("room") ?? 0); // Current room number

  const { rooms } = useTypedSelector((state) => state.rooms); // Get rooms from Redux store
  const { cruise } = useTypedSelector((state) => state.search); // Get cruise data from Redux store
  const { app_language, skip_payment_step } = useTypedSelector(
    (state) => state.environment,
  ); // Get app language and skip payment boolean value from Redux store

  const [isLoading, setIsLoading] = useState(false); // State for loading status

  // const generatePassportDetails = (guestFields: GuestFields): Documents => {
  //   const documentation: Documents = [
  //     {
  //       type: "passport",
  //       number: guestFields.passport_number,
  //       issue_date: guestFields.issue_date,
  //       expiry_date: guestFields.expiry_date,
  //       place_of_birth: guestFields.place_of_birth,
  //       country_of_issue: guestFields.country_of_issue,
  //       gender: guestFields.gender,
  //     },
  //   ];

  //   return documentation;
  // };

  // Function to submit guest information
  const submit = async (guests: SubmitButtonPayload) => {
    setIsLoading(true); // Set loading state to true

    // Prepare payload for reservation request
    const payload = {
      rate_code: rooms?.[1].fare?.rate_code ?? "",
      cruiseId: cruiseId ?? "",
      source: cruise?.source ?? "",
      cabins: Object.values(rooms ?? {}).map((room, roomIndex) => ({
        cabin_number: room.cabin?.number ?? "",
        dining_seating: "",
        dining_table: "",
        grade_code: room.grade?.code ?? "",
        guests: Object.values(guests[roomIndex + 1] ?? {}).map(
          // Mapping guest data
          (guest, guestIndex) => ({
            address: [guest.inputs.address1, guest.inputs.address2].filter(
              (el) => el,
            ),
            city: guest.inputs.city,
            country: guest.inputs.country,
            date_of_birth: `${guest.inputs.yearOfBirth}-${guest.inputs.monthOfBirth}-${guest.inputs.dayOfBirth}`,
            email: guest.inputs.email,
            given_name: guest.inputs.given_name,
            language: app_language,
            lastname: guest.inputs.last_name,
            last_name: guest.inputs.last_name,
            lead_passenger: guestIndex === 0 && roomIndex === 0,
            nationality: guest.inputs.nationality,
            phone: guest.inputs.phone,
            state: guest.inputs.state,
            title: guest.inputs.title,
            gender: guest.inputs.gender,
            zip_code: guest.inputs.zip_code,
            // documentation: generatePassportDetails(guest.inputs),
            request: guest.inputs.request,
          }),
        ),
      })),
    };

    try {
      // Make reservation request
      const { data } = await reservation({ ...payload });
      const updatedStateRooms = structuredClone(rooms);

      // Handle successful reservation
      if (data?.pnr && updatedStateRooms) {
        // Update rooms in Redux store with PNR
        updatedStateRooms[1].pnr = data.pnr;

        const pathParam = skip_payment_step
          ? "payment-confirmation"
          : "payment";

        const params = prepareParams(
          searchParams,
          rooms ?? {},
          currentRoomNumber,
          currentRoomNumber < totalRooms,
        );

        // Update rooms in state
        dispatch(updateRooms(updatedStateRooms));
        navigate(`/search-results/${cruiseId!}/${pathParam}?${params}`);
      }

      // Handle failed reservation
      if (!data) {
        // Dispatch toast message for error
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            duration: TOAST.DEFAULT_DURATION,
            message: t("PNR was not received from the server"),
          }),
        );
      }
    } catch (error) {
      // Dispatch toast message for error
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          duration: TOAST.DEFAULT_DURATION,
          message: t("Reservation creation failed"),
        }),
      );
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  // Expose submit method using useImperativeHandle
  useImperativeHandle(ref, () => ({ submit }), [submit]);

  // Render the SubmitButton component
  return (
    <Button
      id="passengers_submit_button"
      label={t("submit")}
      className={styles.submitButton}
      onClick={onClick}
      loading={isLoading}
      disabled={isLoading}
    />
  );
});

// Export the SubmitButton component
export default SubmitButton;
