// React hooks for state and ref management
import { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
// Utility for conditional class names
import classNames from "classnames";
// Flatpickr for date picking functionality
import Flatpickr from "react-flatpickr";
// Unique ID generation
import { nanoid } from "@reduxjs/toolkit";
// Flatpickr plugins for additional functionality
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import scrollDatePlugin from "flatpickr/dist/plugins/scrollPlugin";
// Flatpickr types
import { Plugin } from "flatpickr/dist/types/options";
// Day.js for date manipulation
import dayjs, { Dayjs } from "dayjs";
// Translation hook
import { useTranslation } from "react-i18next";

// Redux hook for accessing the global state
import { useTypedSelector } from "../../../store/store";

// Component-specific styles
import styles from "./index.module.scss";
// Flatpickr themes
import "flatpickr/dist/themes/light.css";
import "flatpickr/dist/plugins/monthSelect/style.css";
// Custom styles for this component
import "./index.scss";

// Interface for DatePicker props
interface IDatePickerProps {
  defaultDate?: string[];
  valueKey?: string;
  displayFormat?: string;
  returnFormat?: string;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  mode?: "time" | "multiple" | "range" | "single";
  select?: "month" | "day";
  label?: string;
  errorMessage?: string;
  placeholder: string;
  name?: string;
  isRequired?: boolean;
  className?: string;
  onChange?: ({
    value,
    valueKey,
  }: {
    value: string[];
    valueKey?: string;
  }) => void;
}

// Interface for DatePicker handler methods
export interface DatePickerHandler {
  handleResetDate: () => void;
}

// Plugins for month selection and date scrolling
const monthSelect: Plugin = new (monthSelectPlugin as any)({
  dateFormat: "F Y",
  theme: "light",
});

const scrollDate: Plugin = new (scrollDatePlugin as any)({
  dateFormat: "mMM-Y",
  theme: "light",
});

// Linking date selection modes to their respective plugins
const datePluginsLinking = {
  month: monthSelect,
  day: scrollDate,
};

// DatePicker component with forwardRef for ref handling
const DatePicker = forwardRef<DatePickerHandler, IDatePickerProps>(
  (
    {
      defaultDate,
      valueKey,
      displayFormat,
      returnFormat,
      minDate,
      maxDate,
      mode,
      select,
      label,
      errorMessage,
      placeholder,
      name,
      isRequired,
      className,
      onChange,
    },
    ref,
  ) => {
    // Translation and global state hooks
    const { t } = useTranslation();

    const { date_format } = useTypedSelector((state) => state.environment);

    // Unique IDs for the input and the picker itself
    const id = useMemo(nanoid, []);
    // Ref for the Flatpickr instance
    const flatpickrRef = useRef<Flatpickr>(null);

    // Plugin based on the selection mode (month/day)
    const pickerPlugin = useMemo(
      () => datePluginsLinking[select ?? "month"],
      [],
    );

    // Check if the default date is provided and valid
    const isDefaultDateProvided = useMemo(
      () => defaultDate && defaultDate?.filter((el) => el).length > 0,
      [defaultDate],
    );

    // Handler for date changes
    const handleDateChange = (newDate: Date[]) => {
      // Formatting the date according to the specified format
      const value = newDate.map((date) => {
        const formattedNow = dayjs().format(returnFormat);
        const formattedDate = dayjs(
          formattedNow,
          { format: returnFormat },
          true,
        );
        const isDateValid = formattedDate.isValid();

        // Returning the formatted date or defaulting to the global date format
        if (isDateValid) {
          return dayjs(date).format(returnFormat);
        } else {
          return dayjs(date).format(date_format || "MM/DD/YYYY");
        }
      });

      // Triggering onChange callback if provided
      if (onChange && (mode !== "range" || value.length > 0)) {
        // Check if the selected dates are the same
        if (
          value.length === 2 &&
          value[0] === value[1] &&
          valueKey === "date"
        ) {
          value[1] = "";
        }

        onChange({ value, valueKey });
      }
    };

    // Exposing the reset method to the parent component
    useImperativeHandle(ref, () => ({
      handleResetDate: () => flatpickrRef.current?.flatpickr.clear(),
    }));

    // Returning the Flatpickr component with configured options
    return (
      <div className={classNames(styles.container, className)}>
        {label && (
          <label htmlFor={id} className={styles.label}>
            {label}
            {isRequired && <span className={styles.asterisk}>*</span>}
          </label>
        )}

        <Flatpickr
          ref={flatpickrRef}
          name={name}
          value={
            isDefaultDateProvided
              ? defaultDate?.map((el) => dayjs(el).toDate())
              : defaultDate
          }
          className={classNames(styles.picker, {
            [styles.picker_error]: !!errorMessage,
          })}
          type="text"
          options={{
            mode,
            minDate: minDate ? minDate.toDate() : undefined,
            maxDate: maxDate ? maxDate.toDate() : undefined,
            plugins: [pickerPlugin],
            dateFormat: displayFormat ?? "MM-YYYY",
            defaultDate: isDefaultDateProvided
              ? defaultDate?.map((el) => dayjs(el).toDate())
              : defaultDate,
          }}
          placeholder={placeholder || t("Select date")}
          onChange={handleDateChange}
          onClose={handleDateChange}
        />

        <span className={styles.error}>{errorMessage}</span>
      </div>
    );
  },
);

// Default props for the DatePicker component
DatePicker.defaultProps = {
  defaultDate: [],
  valueKey: "",
  returnFormat: "",
  mode: "range",
  select: "month",
  label: "",
  errorMessage: "",
  placeholder: "",
  name: "",
  isRequired: false,
  className: "",
  onChange: () => {},
};

// Setting the display name for debugging purposes
DatePicker.displayName = "DatePicker";

// Exporting the component
export default DatePicker;
