export function insertValuesToString(
  template: string,
  values: Record<string, string>,
) {
  const result = template.replace(
    /{{\s*(\w+)\s*}}/g,
    (_, key) => values[key] || "",
  );

  return result;
}
