// Import route constants.
import { useTypedSelector } from "../../../store/store";
import formatPhoneNumber from "../../../utils/helpers/formatPhoneNumber";

// Import stylesheet for this component.
import styles from "./index.module.scss";

// Footer component definition.
function Footer() {
  const { footer_address, footer_email_address, header_telephone_number } =
    useTypedSelector((state) => state.environment);

  // Render footer with a container style and include the Logo component,
  // which uses the ROOT constant for its pathname prop, likely directing to the homepage.
  return (
    <footer className={styles.container}>
      {(footer_address || footer_email_address) && (
        <div className={styles.address}>
          {footer_address && <p>{footer_address}</p>}

          {footer_email_address && (
            <a className={styles.email} href={`mailto:${footer_email_address}`}>
              {footer_email_address}
            </a>
          )}
        </div>
      )}

      {header_telephone_number && (
        <a className={styles.telephone} href={`tel:${header_telephone_number}`}>
          {formatPhoneNumber(header_telephone_number)}
        </a>
      )}
    </footer>
  );
}

// Make the Footer component available for import.
export default Footer;
